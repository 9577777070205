import React, { useState } from "react";
import {
  Filter_Data_Transaction,
  getSsTransaction,
  useSsTransaction,
} from "../../../redux/slices/ssTransactionSlice";
import {
  DROPDOWN_ALL_VALUE,
  FILTER,
} from "../../../constant";
import { useEffect } from "react";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  CardBody,
  Form,
  Row,
  Col,
} from "reactstrap";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import StatsHorizontal from "../../../components/widgets/stats/StatsHorizontal";
import Totalpayout from "../../../assets/images/pages/totalpayout.svg";
import { Table, TableRow } from "../../../components/Table";
import { useNavigate } from "react-router-dom";
import { getStatusStyle } from "../../../theme";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useRole } from "../../../redux/slices/authSlice";

const SsTransaction = () => {
  const { ssTransactionFilterState } = useSelector(
    (state) => state.ssTransaction
  );
  const [refresh, setRefresh] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({ ...FILTER });
  const [isOpen, setIsOpen] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);

  const ssTransactionState = useSsTransaction();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useRole("SS Deposit");

  useEffect(() => {
    getFilterTransactions();
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const searchDataOptions = [
    { value: "merchantOrderId", label: "Order Id" },
    { value: "txnId", label: "Transaction Id" },
    { value: "bankRrn", label: "UTR" },
    { value: "custId", label: "Player Id" },
    { value: "paymentAmt", label: "Amount" },
  ];

  const statusDataOptions = [
    { value: "Success", label: "Success" },
    { value: "Processing", label: "Processing" },
    { value: "Initialized", label: "Initialized" },
    { value: "Pending", label: "Pending" },
    { value: "Failed", label: "Failed" },
    { value: "BankDown", label: "BankDown" },
    { value: "Open", label: "Open" },
    { value: "Not Attempted", label: "Not Attempted" },
  ];

  const initialValues = {
    filter: searchDataOptions?.[0]?.value,
    bankId: DROPDOWN_ALL_VALUE,
    status: DROPDOWN_ALL_VALUE,
    merchantId: DROPDOWN_ALL_VALUE,
    date: [null, null],
    search: "",
  };

  const prepareBankIdDataOptions = () => {
    const options = ssTransactionState?.bankList
      ?.slice()
      ?.sort((a, b) => a?.label?.localeCompare(b?.label))
      ?.map((val) => {
        const options = {
          value: val?.account_id,
          label: val?.label,
        };
        return options;
      });
    return options;
  };

  const prepareclientDataOptions = () => {
    const options = ssTransactionFilterState?.cilent_data
      ?.slice()
      ?.sort((a, b) => a?.merchantName?.localeCompare(b?.merchantName))
      ?.map((val) => {
        const clientDataOption = {
          value: val?.merchantId,
          label: val?.merchantName,
        };
        return clientDataOption;
      });
    return options;
  };

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...FILTER,
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const getFilterTransactions = async () => {
    try {
      await dispatch(Filter_Data_Transaction()).unwrap();
    } catch (err) { }
  };

  const onGetSsTransactions = async (loading = false) => {
    try {
      if (loading) {
        setIsLoading(true);
      }
      const {
        isFilter,
        page_no,
        limit,
        filter: dropdown,
        search,
        date,
        status,
        ...rest
      } = filter;

      const payload = {
        filter_data: {
          [dropdown]: search,
          startDate: dateFormatter(isFilter ? date?.[0] : new Date(), "start"),
          endDate: dateFormatter(
            isFilter ? date?.[1] : new Date(),
            "end",
            date?.[0]
          ),
          status,
          ...rest,
        },
        page_no,
        limit,
      };
      const allRes = await Promise.all([
        dispatch(getSsTransaction(payload)).unwrap(),
      ]);
      if (filter?.isFilter) {
        responseToaster(allRes?.[0]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (role.includes("ssTransaction-list")) {
      onGetSsTransactions(true);
    }
  }, [filter]);

  useEffect(() => {
    if (role.includes("ssTransaction-list")) {
      const IntervalClick = setInterval(() => {
        if (refresh) {
          onGetSsTransactions(true);
        }
      }, 9000);
      return () => clearInterval(IntervalClick);
    }
  }, [refresh, filter]);

  const columns = [
    {
      title: "DATE",
      name: "createdAtIst",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Create : </span>
                  {data?.createdAtIst || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Update : </span>
                  {data?.updatedAtIst || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "TRANSACTION",
      name: "txnId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.txnId ? (
                <div
                  className="order-singal-div cursor-pointer"
                  onClick={() => {
                    navigate(`/ss-transaction/${data?.txnId}`);
                  }}
                >
                  <p className="themecolor">
                    <span> ID : </span>
                    {data?.txnId}
                  </p>
                </div>
              ) : null}
              {data?.merchantOrderId ? (
                <div className="order-singal-div">
                  <p>
                    <span> Order : </span>
                    {data?.merchantOrderId}
                  </p>
                </div>
              ) : null}
              {data?.pgType ? (
                <div className="order-singal-div">
                  <p>
                    <span> UTR : </span>
                    {data?.utr}
                  </p>
                </div>
              ) : null}
              {data?.tempBankUtr ? (
                <div className="order-singal-div">
                  <p>
                    <span>Temp. UTR : </span>
                    {data?.tempBankUtr}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "CLIENT",
      name: "merchantName",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>{data?.merchantDatas?.merchantName || ""}</p>
              </div>
              <div className="order-singal-div">
                <p>{data?.merchantId || ""}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "CUSTOMER",
      name: "custId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.custId ? (
                <div className="order-singal-div">
                  <p>{data?.custId || ""}</p>
                </div>
              ) : null}
              {data?.custName ? (
                <div className="order-singal-div">
                  <p>{data?.custName}</p>
                </div>
              ) : null}
              {data?.custEmail ? (
                <div className="order-singal-div">
                  <p>{data?.custEmail}</p>
                </div>
              ) : null}
              {data?.custMobile ? (
                <div className="order-singal-div">
                  <p>{data?.custMobile}</p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      name: "paymentAmt",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.paymentAmt ? (
                <div className="order-singal-div">
                  <p>{`${seperator(data?.paymentAmt)}`}</p>
                </div>
              ) : null}
              {data?.associateFees ? (
                <div className="order-singal-div">
                  <p>
                    <span>Asso.Fee : </span>
                    {`${seperator(data?.associateFees)}`}
                  </p>
                </div>
              ) : null}
              {data?.pgFees ? (
                <div className="order-singal-div">
                  <p>
                    <span>Pg Fees : </span>
                    {seperator(data?.pgFees) || ""}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "STATUS",
      name: "paymentSts",
      Cell: (data) => {
        const { bgClassName } = getStatusStyle(data?.paymentSts);
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div>
                <span className={`${bgClassName}`}>{data?.paymentSts}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PG",
      name: "pgLabel",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.pgLabel ? (
                <div className="order-singal-div">
                  <p>
                    <span> Label : </span>
                    {data?.pgLabel}
                  </p>
                </div>
              ) : null}
              {data?.account_number ? (
                <div className="order-singal-div">
                  <p>
                    <span> Account : </span>
                    {data?.account_number}
                  </p>
                </div>
              ) : null}
              {data?.upiId ? (
                <div className="order-singal-div">
                  <p>
                    <span> UPI : </span>
                    {data?.upiId}
                  </p>
                </div>
              ) : null}
              {data?.bankName ? (
                <div className="order-singal-div">
                  <p>
                    <span> Bank : </span>
                    {data?.bankName}
                  </p>
                </div>
              ) : null}
              {data?.ifscCode ? (
                <div className="order-singal-div">
                  <p>
                    <span> IFSC : </span>
                    {data?.ifscCode}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "AGENT DETAILS",
      name: "merchantOrderId",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              {data?.processBy ? (
                <div className="order-singal-div">
                  <p>
                    <span> processBy : </span>
                    {data?.processBy || ""}
                  </p>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
  ];

  if (!role.includes("ssTransaction-list")) {
    return
  }

  return (
    role.includes("ssTransaction-list") ?
      <div className={refresh && isLoading ? "opacity-25" : ""}>
        <Card>
          <CardHeader className="flex-column align-items-start">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h4 className="main-title ">
                <b>SS Deposit</b>
              </h4>
              <div
                style={{ display: "flex", justifyContent: "end", gap: "10px" }}
              >
                <div className="mb-xl-0 d-flex align-items-center">
                  <Button
                    type="button"
                    className={`${refresh ? "" : "filterbtn"} gap`}
                    color={refresh ? "primary" : ""}
                    onClick={() => setRefresh(!refresh)}
                    title="Auto refresh"
                  >
                    {refresh ? (
                      <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                    ) : (
                      <i className="fa-solid fa-arrows-rotate"></i>
                    )}
                    Auto Refresh
                  </Button>
                </div>
                <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                    id="page-header-user-dropdown"
                    tag="button"
                    title="Column Filter"
                  >
                    <IoFilterOutline
                      value={{ color: "#7367F0" }}
                      fill="#7367F0"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="z-3"
                    style={{
                      whiteSpace: "nowrap",
                      padding: 20,
                      transform: "translate(-20px, 22px)",
                    }}
                  >
                    <li className="z-3 text-dark">
                      <span>
                        <i className="fa-solid fa-filter"></i> Filter Column
                      </span>
                    </li>
                    <div className="border-bottom border-light mt-2">
                      {columns?.map((column) => {
                        return (
                          <div className="d-flex justify-content-between">
                            <p>{column?.title}</p>
                            <Switch
                              checked={filterColumns?.includes(column?.name)}
                              size="small"
                              onChange={(e) => {
                                const newData = [...filterColumns];
                                const index = newData?.indexOf(column?.name);
                                if (index > -1) {
                                  newData.splice(index, 1);
                                } else {
                                  newData.push(column?.name);
                                }
                                setFilterColumns(newData);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Form
              className="transaction-main-div"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="d-flex align-items-end button-space flex-wrap">
                <ControlledDropDown
                  id="filter"
                  name="filter"
                  label="Filter"
                  isDefaultOption={false}
                  options={searchDataOptions}
                  control={control}
                />
                <ControlledInput
                  name="search"
                  placeholder="Enter Search Value"
                  label="Search"
                  control={control}
                />

                <ControlledDropDown
                  id="type"
                  name="status"
                  label="Status"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={statusDataOptions}
                  control={control}
                />
                <ControlledDropDown
                  label="Bank Label"
                  id="bankId"
                  name="bankId"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={prepareBankIdDataOptions()}
                  control={control}
                />
                <ControlledDropDown
                  label="Client"
                  id="merchantId"
                  name="merchantId"
                  isDefaultOption={DROPDOWN_ALL_VALUE}
                  options={prepareclientDataOptions()}
                  control={control}
                />
                <ControlledDatePicker
                  name="date"
                  placeholder="Select Date"
                  label="Date"
                  maxDate={new Date()}
                  selectsRange
                  control={control}
                />

                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={() => {
                    reset();
                    setFilter({ ...FILTER });
                  }}
                >
                  Clear
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <div className="merchant-management-card w-100">
          <div style={{ display: "unset" }}>
            <Row>
              <Col lg="3" sm="6" className="pe-0">
                <Card className="dollar-icon-main">
                  <CardHeader>
                    <StatsHorizontal
                      className="fontstyle greentext"
                      color="primary"
                      image={Totalpayout}
                      statTitle={seperator(
                        ssTransactionState?.summary?.total_txn,
                        false
                      )}
                      renderStats={
                        <h5 className="fw-bolder mb-0">TOTAL DEPOSIT</h5>
                      }
                    />
                  </CardHeader>
                </Card>
              </Col>
              <Col lg="3" sm="6" className="pe-0">
                <Card className="dollar-icon-main">
                  <CardHeader>
                    <StatsHorizontal
                      className="fontstyle greentext"
                      color="primary"
                      image={Totalpayout}
                      statTitle={seperator(
                        ssTransactionState?.summary?.total_payment_amount
                      )}
                      renderStats={
                        <h5 className="fw-bolder mb-0">TOTAL PAYMENT AMOUNT</h5>
                      }
                    />
                  </CardHeader>
                </Card>
              </Col>
              <Col lg="3" sm="6" className="pe-0">
                <Card className="dollar-icon-main">
                  <CardHeader>
                    <StatsHorizontal
                      className="fontstyle greentext"
                      color="primary"
                      image={Totalpayout}
                      statTitle={seperator(
                        ssTransactionState?.summary?.total_payable_amount
                      )}
                      renderStats={
                        <h5 className="fw-bolder mb-0">TOTAL PAYABLE AMOUNT</h5>
                      }
                    />
                  </CardHeader>
                </Card>
              </Col>
              <Col lg="3" sm="6" className="">
                <Card className="dollar-icon-main">
                  <CardHeader>
                    <StatsHorizontal
                      className="fontstyle greentext"
                      color="primary"
                      image={Totalpayout}
                      statTitle={seperator(
                        ssTransactionState?.summary?.total_pg_fees
                      )}
                      renderStats={<h5 className="fw-bolder mb-0">TOTAL FEES</h5>}
                    />
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Card>
          <Table
            columns={columns}
            isLoading={isLoading && !refresh}
            data={ssTransactionState?.data || []}
            isData={!!ssTransactionState?.data?.length}
            filterColumns={filterColumns}
            count={ssTransactionState?.total_item || 0}
            pagination={filter}
            isExpandable={false}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
            manualRowsPerPage
          >
            {ssTransactionState?.data?.map((item) => {
              return (
                <TableRow
                  tdClassName="position-relative"
                  columns={columns}
                  item={item}
                  filterColumns={filterColumns}
                  isExpandable={false}
                ></TableRow>
              );
            })}
          </Table>
        </Card>
      </div> : null
  );
};

export default SsTransaction;
