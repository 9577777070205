import { useEffect, useState, Fragment, useRef } from "react";

// UI IMPORT
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Form,
    Input,
} from "reactstrap";

// PROJECT IMPORT
import {
    setLocalData,
    getLocalData,
    responseToaster,
    getLocalDataa,
} from "../../../helperFunctions";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
    smsGet,
    usersGet,
    useSms,
    useUserList,
} from "../../../redux/slices/smsSlice";
import { FILTER, SMS_LOCAL } from "../../../constant";
import { statusOptions } from "./mock";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import { Table, TableRow } from "../../../components/Table";
import { useDispatch } from "react-redux";
import InputField from "../../../components/Custom/Forms/InputField/InputField";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import { useRole } from "../../../redux/slices/authSlice";

const SMS = () => {
    const data = useSms();
    const role = useRole("Sms Sync");
    const userList = useUserList();
    const isInitialMount = useRef(true);
    const [tableData, setTableData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    // const [value] = useDebounce(searchTerm, 1000);
    const [filter, setFilter] = useState(getLocalDataa(SMS_LOCAL, FILTER));
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [filterColumns, setFilterColumns] = useState([]);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { handleSubmit, control, reset, getValues, watch } = useForm({
        defaultValues: {
            userId: statusOptions?.[0]?.value,
            nickNameId: statusOptions?.[0]?.value,
        },
    });
    const columns = [
        {
            title: "Username",
            name: "user_name",
            Cell: (data) => <>{data?.user_details?.user_name}</>,
        },
        {
            title: "Nickname",
            name: "nick_name",
            Cell: (data) => (
                <>
                    <div>
                        {data?.nick_name_details?.length
                            ? data?.nick_name_details?.map((val) => {
                                return (
                                    <p
                                        className="text-primary cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            {
                                                !val?.nick_name && role.includes("sms-user-list") ?
                                                navigate(`/getSms/user/${data?.user_id}`, {
                                                    state: {
                                                        nickID: val?.id,
                                                        // nickName: val?.nick_name,
                                                    },
                                                }) : null;
                                            }
                                        }}
                                    >
                                        {val?.nick_name || "Set Nickname"}
                                    </p>
                                );
                            })
                            : "-"}
                    </div>
                </>
            ),
        },
        {
            title: "Sender ID",
            name: "sender_id",
        },
        {
            title: "Text",
            name: "text",
            type: "ellipsis",
        },
        {
            title: "Actual Date & Time",
            name: "sms_date_time",
            sortableKey: "sms_date_time",
            sortable: true,
        },
        {
            title: "Sync Date & Time",
            name: "created_at_ist",
            sortableKey: "created_at_ist",
            sortable: true,
        },
    ];

    useEffect(() => {
        const newColumns = columns?.map((item) => item?.name);
        setFilterColumns(newColumns);
    }, []);

    const prepareUserOptions = () => {
        const options = (userList?.data ?? [])?.map((val) => {
            return {
                value: val?.id,
                label: val?.user_name,
            };
        });
        return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
    };

    const prepareNickUserOptions = () => {
        const options = (userList?.NickNameList ?? [])
            ?.filter((item) => !!item?.nick_name)
            ?.map((val) => {
                return {
                    value: val?.id,
                    label: val?.nick_name,
                };
            });
        return options?.length ? [{ value: "All", label: "All" }, ...options] : [];
    };

    const onGetData = async (loading = false, isToast = true) => {
        try {
            if (loading) {
                setIsLoading(true);
            }
            const { isFilter, page_no, limit, ...rest } = filter;
            const payload = {
                filter_data: {
                    // searchData: searchTerm,
                    ...rest,
                },
                page_no,
                limit,
            };
            const res = await dispatch(smsGet(payload)).unwrap();
            if (isFilter && isToast) {
                responseToaster(res);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (role.includes("sms-list")) {
            onGetData(true);
        }
        // eslint-disable-next-line
    }, [filter]);
   /*  useEffect(() => {
        if (role.includes("sms-list")) {
            const getData = setTimeout(() => {
                onGetData(true);
            }, 1000);

            return () => clearTimeout(getData)
        }
        // eslint-disable-next-line
    }, [searchTerm]); */

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        setLocalData(SMS_LOCAL, {
            page_no: filter?.page_no,
            limit: filter?.limit,
        });
        // eslint-disable-next-line
    }, [filter?.page_no, filter?.limit]);

    useEffect(() => {
        setTableData(data?.data?.length ? [...data?.data] : []);
    }, [data]);

    useEffect(() => {
        if (role.includes("sms-user-list")) {
            getSmsUser();
        }
    }, []);

    const getSmsUser = async () => {
        try {
            await dispatch(usersGet())?.unwrap();
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        if (role.includes("sms-user-list")) {
            const IntervalClick = setInterval(() => {
                if (refresh) {
                    onGetData(true, false);
                    getSmsUser();
                }
            }, 10000);
            return () => clearInterval(IntervalClick);
        }
    }, [refresh, filter, searchTerm]);

    const onSubmit = async () => {
        const values = getValues();
        setFilter({ ...filter, ...values, ...FILTER, isFilter: true });
    };

    if (!role.includes("sms-list")) {
        return
    }

    return (
        <>
            <div className={`${isLoading && refresh ? "opacity-25" : ""}`}>
                <CardBody className="pt-0">
                    <div className="flex-column align-items-start">
                        <div className="d-flex align-items-center w-100 justify-content-between">
                            <Form
                                className="d-flex my-xl-auto right-content align-items-end button-space"
                                onSubmit={handleSubmit(onSubmit)}
                            >

                                <ControlledDropDown
                                    name="userId"
                                    label="Username"
                                    options={prepareUserOptions()}
                                    control={control}
                                />
                                <ControlledDropDown
                                    name="nickNameId"
                                    label="Nickname"
                                    options={prepareNickUserOptions()}
                                    control={control}
                                />
                                <Button type="submit" color="primary">
                                    Apply
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    onClick={() => {
                                        reset();
                                        setFilter({ ...FILTER });
                                    }}
                                >
                                    Clear
                                </Button>

                            </Form>

                            <div className="d-flex gap-50 flex-row justify-content-end">
                                <span className=" aurorefresh-btn">
                                    <Button
                                        type="button"
                                        className={`${refresh ? "" : "filterbtn"} gap`}
                                        color={refresh ? "primary" : ""}
                                        onClick={() => setRefresh(!refresh)}
                                        title="Auto refresh"
                                    >
                                        {refresh ? (
                                            <i className="fa-solid fa-arrows-rotate  rotate-animation"></i>
                                        ) : (
                                            <i className="fa-solid fa-arrows-rotate"></i>
                                        )}
                                        Auto Refresh
                                    </Button>
                                </span>
                                {/* <InputField
                                    name="searchData"
                                    className="w-100"
                                    rightIcon={searchTerm !== "" ? <IoMdClose /> : <IoMdSearch />}
                                    righticonClick={() => setSearchTerm("")}
                                    placeholder="Enter text"
                                    value={searchTerm}
                                    type="text"
                                    autoComplete="off"
                                    onChange={(event) => {
                                        setSearchTerm(event.target.value);
                                    }}
                                /> */}
                            </div>
                        </div>
                    </div>
                </CardBody>
                <div className="App">
                    <Table
                        columns={columns}
                        isLoading={isLoading && !refresh}
                        data={tableData}
                        isData={!!tableData?.length}
                        filterColumns={filterColumns}
                        count={data?.total_item || 0}
                        pagination={filter}
                        isExpandable={false}
                        setData={setTableData}
                        handlePaginationChange={(pagination) => {
                            setFilter({
                                ...filter,
                                ...pagination,
                                isFilter: false,
                            });
                        }}
                        onColumnsChange={(columns) => {
                            setFilterColumns(columns);
                        }}
                    >
                        {tableData?.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <TableRow
                                        columns={columns}
                                        isExpandable={false}
                                        item={item}
                                        filterColumns={filterColumns}
                                    ></TableRow>
                                </Fragment>
                            );
                        })}
                    </Table>
                </div>
            </div>
        </>
    );
};

export default SMS;
