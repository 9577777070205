import { toast } from "react-hot-toast";
import { FiAlertTriangle } from "react-icons/fi";

export const LIMIT = 100;
export const PAGINATION_OPTIONS = [10, 25, 50, 100];

export const TRA_LIMIT = 100;
export const TRA_PAGINATION_OPTIONS = [50, 100, 200, 500, 1000];

export const TRA_FILTER = {
  page_no: 1,
  limit: TRA_LIMIT,
  isFilter: false,
};
export const FILTER = {
  page_no: 1,
  limit: LIMIT,
  isFilter: false,
};

export const MERCHANT_LIMIT = 50;

export const DATE_RANGE = [null, null];

export const LOGOUT_TIME = 1000 * 60 * 60 * 24;

export const LOGOUT_EVENTS = [
  "mousedown",
  "mousemove",
  "wheel",
  "keydown",
  "touchstart",
  "scroll",
];

export const SMS_LOCAL = "smsPagination";
export const USER_LOCAL = "userPagination";

export const showToaster = (message, type = "Success") => {
  switch (type) {
    case "Error":
      toast.error(message || "Something Went Wrong!", {
        position: "top-right",
      });
      break;
    case "Success":
      toast.success(message, {
        position: "top-right",
      });
      break;
    case "Warning":
      toast.custom(
        (t) => (
          <div className="custom-toast">
            <FiAlertTriangle size={24} /> {/* Use the warning icon */}
            <span>{message}</span>
          </div>
        ),
        {
          position: "top-right",
        }
      );
      break;
    default:
      toast.success(message, {
        position: "top-right",
      });
      break;
  }
};

export const centralBankUrls = ["apihuk"];
export const restricForFingerPrintUrls = ["directpayz.com"];

// IS FILTER OPEN LOCAL NAMES
export const MERCHANT_LOCAL = "isMerchantFilterOpen";
export const BANK_STATEMENT_LOCAL = "isBankStatementFilterOpen";
export const BANK_SUMMARY_DEPOSIT_LOCAL = "isBankSummaryDepositFilterOpen";
export const BANK_SUMMARY_WITHDRAWAL_LOCAL =
  "isBankSummaryWithdrawalFilterOpen";
export const SUPPORT_LOG_LOCAL = "isSupportLogFilterOpen";
export const WEBHOOK_EVENTS_LOCAL = "isWebhookEventsFilterOpen";
export const PG_LOCAL = "isPGFilterOpen";
export const BOUNCER_LOCAL = "isBouncerFilterOpen";
export const FIXED_DECIMAL = 2;

export const DROPDOWN_ALL_VALUE = "All";
export const DROPDOWN_ALL_LABEL = "All";

export const DROPDOWN_SELECT_VALUE = "";
export const DROPDOWN_SELECT_LABEL = "Select";

export const DROPDOWN_SEARCH_VALUE = "searchdata";
export const DROPDOWN_SEARCH_LABEL = "Search Data";

export const UPPERCASE_LABEL = {
  ifsc_code: "IFSC Code",
  upi_id: "UPI Id"
};

export const NUMBER_TYPE_FIELDS = ["account_number"]

export const FINGER_KEY = "9VfHoavZPI0wTG4XyLus"
export const FINGER_REGION_KEY = "ap"
