import React from "react";
import "./Payout_Manual.scss";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  Mark_as_Manual_Payout,
  Payout_Banklist_Manual,
  payout_Manual,
} from "../../../redux/slices/payinPayoutSlice";
import { useEffect } from "react";
import { Table, TableRow } from "../../../components/Table";
import {
  DROPDOWN_ALL_VALUE,
  TRA_FILTER,
  TRA_PAGINATION_OPTIONS,
} from "../../../constant";
import { Switch } from "@mui/material";
import { IoFilterOutline } from "react-icons/io5";
import AccounLoadModal from "../../../components/Custom/Modals/AccoutLoadModal/AccounLoadModal";
import GenerateBankfileModal from "../../../components/Custom/Modals/GenerateBankfileModal/GenerateBankfileModal";
import UploadStatusFileModal from "../../../components/Custom/Modals/UploadStatusFileModal/UploadStatusFileModal";
import {
  dateFormatter,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { baseUrl } from "../../../redux/api/baseUrl";
import { useRole } from "../../../redux/slices/authSlice";

const Payout_Manual = () => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [filter, setFilter] = useState({ ...TRA_FILTER });
  const dispatch = useDispatch();
  const { manualPayloadState, manualpayoutBankState, manualpayoutBankLoading } =
    useSelector((state) => state.payinPayout);
  const role = useRole("Withdrawal Manual");
  const [accountLoadModal, setAccountLoadModal] = useState(false);
  const [generateBankModal, setGenerateBankModal] = useState(false);
  const [uploadStatusFileModal, setUploadStatusFileModal] = useState(false);

  const onToggleAcoountLodModal = () => {
    setAccountLoadModal(!accountLoadModal);
  };
  const onCloseGeneratebankModal = () => {
    setGenerateBankModal(false);
  };
  const onCloseUploadStatusFileModal = () => {
    setUploadStatusFileModal(false);
  };
  const searchDataOptions = [
    { value: "batchId", label: "Batch Id" },
    { value: "bankName", label: "Bank Name" },
  ];

  const initialValues = {
    filter: searchDataOptions?.[0]?.value,
    date: [null, null],
    search: "",
    debitAc: DROPDOWN_ALL_VALUE,
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const Mark_As_manual_payout = async (id) => {
    try {
      let response = await dispatch(
        Mark_as_Manual_Payout({ batchId: id })
      ).unwrap();

      responseToaster(response);
      if (!!response) {
        payout_Manual_Get();
      } else {
      }
    } catch (err) {}
  };
  const downloadFileFromURL = (event, id) => {
    event.preventDefault();
    const url = `${baseUrl}api/download/batch/file/${id}`;
    if (url) {
      const a = document.createElement("a");
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const columns = [
    {
      title: "DETAILS",
      name: "debitAc",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Date : </span>
                  {data?.initiated_at || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Batch ID : </span>
                  {data?.batchId || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Merchant Name : </span>
                  {data?.merchantList?.[0]?.merchant_details?.merchant_name ||
                    ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> File Name : </span>
                  {data?.fileName || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "BANK",
      name: "bank",
      Cell: (data) => {
        return (
          <>
            <div
              className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}
            >
              <div className="order-singal-div">
                <p>
                  <span> Name : </span>
                  {data?.bankName || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> A/c No : </span>
                  {data?.debitAc || ""}
                </p>
              </div>
              <div className="order-singal-div">
                <p>
                  <span> Label : </span>
                  {data?.acHolder || ""}
                </p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "AMOUNT",
      Cell: (data) => {
        return <>{`${seperator(data?.withdrawalAmt)}`}</>;
      },
    },
    {
      title: "RECORD",
      name: "payout_record",
      Cell: (row) => {
        return (
          <>
            <div className="cell-order d-flex gap-2">
              <div>
                <div className="order-singal-div">
                  <p>
                    <span>Total Record : </span>
                    {seperator(row?.withdrawal_record, false)}
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>Success : </span>
                    {seperator(row?.Count?.total_success_payout, false)}
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>Pending : </span>
                    {seperator(row?.Count?.total_pending_payout, false)}
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>Processing : </span>
                    {seperator(row?.Count?.total_processing_payout, false)}
                  </p>
                </div>
              </div>
              <div>
                <div className="order-singal-div">
                  <p>
                    <span>Initialized : </span>
                    {seperator(row?.Count?.total_initialized_payout, false)}
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>Failed : </span>
                    {seperator(row?.Count?.total_failed_payout, false)}
                  </p>
                </div>
                <div className="order-singal-div">
                  <p>
                    <span>Total Return : </span>
                    {seperator(row?.totalReturn, false)}
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "ACTION",
      name: "Action",
      Cell: (row) => {
        return (
          <div>
            {!row?.flag_as_utilized &&
            role?.includes("download-withdrawal-manual") ? (
              <div className="cell-order">
                <Button
                  color={`${row?.isOpen ? "" : "primary"} `}
                  className={`${row?.isOpen ? "filterbtn " : ""} btn-smallsize`}
                  onClick={(e) => downloadFileFromURL(e, row?.batchId)}
                >
                  Download File
                </Button>
              </div>
            ) : null}

            {!row?.flag_as_utilized &&
            role?.includes("withdrawal-manual-mark-as-used") ? (
              <div className="cell-order mt-1">
                <Button
                  color="success"
                  className="btn-smallsize"
                  onClick={() => Mark_As_manual_payout(row?.batchId)}
                >
                  Mark As Complete
                </Button>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const preparebankDataOptions = () => {
    const bankData = manualpayoutBankState?.data?.map((val) => {
      const bankDataOption = {
        value: val?.accountId,
        label: `${val?.bankName} - ${val?.note} -${val?.accountId} `,
      };
      return bankDataOption;
    });
    return bankData;
  };

  const payout_Manual_Get = async () => {
    try {
      const {
        isFilter,
        page_no,
        limit,
        filter: dropdown,
        search,
        date,
        startDate,
        endDate,
        ...rest
      } = filter;
      const payload = isFilter
        ? {
            filter_data: {
              [dropdown]: search,
              startDate: dateFormatter(date?.[0], "start"),
              endDate: dateFormatter(date?.[1], "end", date?.[0]),
              ...rest,
            },
            page_no,
            limit,
          }
        : {
            page_no,
            limit,
          };
      const res = await dispatch(payout_Manual(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) {}
  };

  const Payout_Banklist = async () => {
    try {
      await dispatch(Payout_Banklist_Manual()).unwrap();
    } catch (err) {}
  };

  useEffect(() => {
    if (role?.includes("add-withdrawal-manual")) {
      Payout_Banklist();
    }
  }, []);

  useEffect(() => {
    if (role?.includes("payoutManual-list")) {
      payout_Manual_Get();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      ...TRA_FILTER,
      isFilter: true,
    });
  };

  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  if (!role.includes("payoutManual-list")) {
    return;
  }

  return (
    <div className="payoutmanual-main-div">
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div>
              <h4 className="main-title">
                <b>Withdrawal Manual</b>
              </h4>
            </div>

            <div className="d-flex gap">
              {role?.includes("account-bank-load-list") ? (
                <Button
                  type="button"
                  color="primary"
                  title="Account / Bank Load"
                  onClick={() => onToggleAcoountLodModal()}
                >
                  Account / Bank Load
                </Button>
              ) : null}
              {role?.includes("add-withdrawal-manual") ? (
                <Button
                  type="button"
                  color="primary"
                  title="Generate Bank File"
                  onClick={() => setGenerateBankModal(true)}
                >
                  Generate Bank File
                </Button>
              ) : null}
              {role?.includes("upload-status-file") ? (
                <Button
                  type="button"
                  color="primary"
                  title="Upload Status File"
                  onClick={() => setUploadStatusFileModal(true)}
                >
                  Upload Status File
                </Button>
              ) : null}
              <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                  id="page-header-user-dropdown"
                  tag="button"
                  title="Column Filter"
                  type="button"
                >
                  <IoFilterOutline fill="#7367F0" />
                </DropdownToggle>
                <DropdownMenu
                  className="z-3"
                  style={{
                    whiteSpace: "nowrap",
                    padding: 20,
                    transform: "translate(-20px, 22px)",
                  }}
                >
                  <li className="z-3 text-dark">
                    <span>
                      <i className="fa-solid fa-filter"></i> Filter Column
                    </span>
                  </li>
                  <div className="border-bottom border-light mt-2">
                    {columns?.map((column) => {
                      return (
                        <div className="d-flex justify-content-between">
                          <p>{column?.title}</p>
                          <Switch
                            checked={filterColumns?.includes(column?.name)}
                            size="small"
                            onChange={(e) => {
                              const newData = [...filterColumns];
                              const index = newData?.indexOf(column?.name);
                              if (index > -1) {
                                newData.splice(index, 1);
                              } else {
                                newData.push(column?.name);
                              }
                              setFilterColumns(newData);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div
              className="d-flex my-xl-auto right-content align-items-end button-space "
              style={{ flexWrap: "wrap" }}
            >
              <ControlledDropDown
                id="filter"
                name="filter"
                label="Filter"
                isDefaultOption={false}
                options={searchDataOptions}
                control={control}
              />
              <ControlledInput
                placeholder="Enter Search Value"
                name="search"
                label="Search"
                control={control}
              />

              {role?.includes("add-withdrawal-manual") ? (
                <div className="select-div">
                  <ControlledDropDown
                    inputClassName="selectwidth"
                    id="debitAc"
                    name="debitAc"
                    label="Bank Name"
                    isDefaultOption={DROPDOWN_ALL_VALUE}
                    options={preparebankDataOptions()}
                    control={control}
                  />
                </div>
              ) : null}

              <div className="d-flex flex-column select-div">
                <ControlledDatePicker
                  name="date"
                  placeholder="Select Date"
                  label="Date"
                  maxDate={new Date()}
                  selectsRange
                  control={control}
                />
              </div>
              <Button type="submit" color="primary">
                Apply
              </Button>
              <Button
                type="button"
                color="danger"
                onClick={() => {
                  reset();
                  setFilter({ ...TRA_FILTER });
                }}
              >
                Clear
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <Table
          columns={columns}
          filterColumns={filterColumns}
          isLoading={manualpayoutBankLoading}
          data={manualPayloadState?.data || []}
          isData={!!manualPayloadState?.data?.length}
          count={manualPayloadState?.total_item || 0}
          pagination={filter}
          isExpandable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
          onColumnsChange={(columns) => {
            setFilterColumns(columns);
          }}
          paginationOptions={TRA_PAGINATION_OPTIONS}
        >
          {manualPayloadState?.data?.map((item) => {
            return (
              <TableRow
                columns={columns}
                filterColumns={filterColumns}
                item={item}
                isExpandable={false}
              />
            );
          })}
        </Table>
      </Card>
      <AccounLoadModal
        isOpen={accountLoadModal}
        onHide={onToggleAcoountLodModal}
      />
      <GenerateBankfileModal
        generateBankModal={generateBankModal}
        onCloseGeneratebankModal={onCloseGeneratebankModal}
      />
      <UploadStatusFileModal
        uploadStatusFileModal={uploadStatusFileModal}
        onCloseUploadStatusFileModal={onCloseUploadStatusFileModal}
      />
    </div>
  );
};

export default Payout_Manual;
